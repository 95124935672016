
































import { Component, Inject, Vue } from 'vue-property-decorator';
import PageHeader1 from '@/components/PageHeader1.vue';
import { Context } from 'vuex-smart-module';
import { AppModule } from '@/store/app/AppModule';
import { ApiFacade } from '@/services/ApiFacade';
import { UserRoleIds } from '@/types/UserRoleIds';
import { UserRoleDto } from '@/models/dto/UserRoleDto';

@Component({
  components: { PageHeader1 },
})
export default class VpnAccessView extends Vue {
  @Inject() appStore!: Context<AppModule>;
  @Inject() apiFacade!: ApiFacade;

  get profile() {
    return this.appStore.getters.profile;
  }

  get availablePolygons() {
    return this.profile?.userRolePolygons.filter((p) => p.polygon);
  }

  getKeyApiUrl(polygonId: number, userRole: UserRoleDto) {
    if (userRole.userRoleId === UserRoleIds.admin) {
      return `/api/v1/polygons/${polygonId}/keys/admin.jsonld`;
    }

    return `/api/v1/polygons/${polygonId}/keys/client.jsonld`;
  }

  getButtonCap(userRole: UserRoleDto) {
    if (userRole.userRoleId === UserRoleIds.admin) {
      return 'Скачать ключ Администратора';
    }

    return 'Скачать ключ Пользователя';
  }
}
